body {
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
}

header {
    margin-bottom: 12px;
    align-items: center;
    display: flex;
}

header img {
    height: 75px;
    width: auto;
    margin-right: 12px;
}

header a:last-child img {
    height: 50px;
}

.result .MuiListItemText-primary {
    font-family: "Helvetica", "Arial", sans-serif;
}

.MuiDialog-paperWidthLg {
    width: 100%;
    max-width: 700px !important;
}

.MuiDialog-paperWidthSm[aria-labelledby="map"] {
    max-width: initial;
}

.MuiDialogContent-root hr {
    margin: 0.35em 0;
}

.MuiDialogContent-root .MuiTypography-subtitle1 {
    font-weight: bold;
}

.layout-container {
    min-height: calc(100vh - 2 * 0.35em);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100vw;
    overflow-x: hidden;
    margin: 0.35em;
}

.layout-container .MuiTypography-caption {
    font-size: 0.7rem;
    text-align: center;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
}

.layout-container .MuiTypography-caption:hover {
    opacity: 1;
}

.MuiAlert-message p {
    margin: 0;
}

.Mui-disabled {
    opacity: 0.6;
}